<template>
  <b-card>
    <b-card-header>
      <i class="icon-note"></i> Detail Wallet Transaction History
      <div class="card-header-actions">
        <a
          class="card-header-action"
          href="wallet-transaction"
          rel="noreferrer noopener"
        >
          <small class="text-muted">Back</small>
        </a>
      </div>
    </b-card-header>
    <br />
    <table>
      <tr>
        <td><strong>Transaction Date</strong></td>
        <td>{{ this.created_at }}</td>
      </tr>
      <tr>
        <td><strong>User</strong></td>
        <td>{{ this.user }}</td>
      </tr>
      <tr>
        <td><strong>Transaction Type</strong></td>
        <td>{{ this.transaction_type }}</td>
      </tr>
      <tr>
        <td><strong>Asset Kripto</strong></td>
        <td>{{ this.source_crypto }}</td>
      </tr>
      <tr>
        <td><strong>Nomor Invoice</strong></td>
        <td>{{ this.invoice }}</td>
      </tr>
      <tr>
        <td><strong>Reference Number</strong></td>
        <td>{{ this.reference_number }}</td>
      </tr>
      <tr>
        <td><strong>Description</strong></td>
        <td>
          <strong>{{ this.description }}</strong>
        </td>
      </tr>
      <tr>
        <td><strong>Asset TKO Amount</strong></td>
        <td>{{ this.asset_amount }}</td>
      </tr>
      <tr>
        <td><strong>Asset Amount</strong></td>
        <td>{{ this.trs_amount }}</td>
      </tr>
      <tr>
        <td><strong>Gold Amount</strong></td>
        <td>{{ this.gold_amount }}</td>
      </tr>
      <tr>
        <td><strong>IDR Amount</strong></td>
        <td>{{ this.amount }}</td>
      </tr>
      <tr>
        <td><strong>Biaya Admin</strong></td>
        <td>{{ this.service_fee }}</td>
      </tr>
      <tr v-if="this.transaction_type == 'Buy Gold'">
        <td><strong>Biaya Pajak</strong></td>
        <td>{{ this.gold_tax }}</td>
      </tr>
      <tr>
        <td><strong>Crypto Asset Buy TRS Price</strong></td>
        <td>{{ this.buying_rate }}</td>
      </tr>
      <tr>
        <td><strong>Crypto Asset Sell TRS Price</strong></td>
        <td>{{ this.selling_rate }}</td>
      </tr>
      <tr>
        <td><strong>Gold Buy Price</strong></td>
        <td>{{ this.gold_buying }}</td>
      </tr>
      <tr>
        <td><strong>Gold Sell Price</strong></td>
        <td>{{ this.gold_selling }}</td>
      </tr>
      <tr>
        <td><strong>Transaction Status</strong></td>
        <td>{{ this.status }}</td>
      </tr>
    </table>
  </b-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      created_at: "",
      user: "",
      transaction_type: "",
      source_crypto: "",
      invoice: "",
      description: "",
      asset_amount: "",
      amount: "",
      buying_rate: "",
      trs_amount: "",
      selling_rate: "",
      reference_number: "",
      status: "",
      gold_amount: "",
      gold_tax: "",
      gold_buying: "",
      gold_selling: "",
      service_fee: "",
      errors: {
        code: "",
        message: "",
        status: ""
      }
    };
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
    this.$http
      .get(`wallet-transaction/` + atob(this.$route.params.id))
      .then(result => {
        let res = result.data.data;
        this.created_at = res.created_at;
        this.user = res.user;
        this.transaction_type = res.transaction_type;
        this.source_crypto = res.source_crypto;
        this.invoice = res.invoice;
        this.description = res.description;
        this.asset_amount = res.asset_amount;
        this.trs_amount = res.trs_amount;
        this.amount = res.amount;
        this.buying_rate = res.buying_rate;
        this.selling_rate = res.selling_rate;
        this.status = res.status;
        this.service_fee = res.service_fee;
        this.gold_amount = res.gold_transactions.amount;
        this.gold_buying = res.gold_transactions.buying_rate;
        this.gold_selling = res.gold_transactions.selling_rate;
        this.gold_tax = res.gold_transactions.tax;
        this.reference_number = res.order_id;
      })
      .catch(error => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      });
  }
};
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>
